/* client/src/pages/admin/pages/editPageImage.css */

.page-image-manager {
    background-color: var(--form-background);
    border: 1px solid var(--content-border-color);
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .page-image-manager h2 {
    font-size: 1.2rem;
    margin-top: 0;
    margin-bottom: 1rem;
    color: var(--text-color);
  }
  
  .image-controls {
    display: flex;
    justify-content: center;
  }
  
  .image-preview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
  
  .page-image-preview {
    max-width: 100%;
    max-height: 250px;
    border-radius: 4px;
    object-fit: contain;
    margin-bottom: 1rem;
    border: 1px solid var(--content-border-color);
    background-color: var(--background-color);
  }
  
  .image-actions {
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
  }
  
  .change-image-button, 
  .select-image-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s ease;
  }
  
  .change-image-button:hover, 
  .select-image-button:hover {
    background-color: var(--secondary-color);
  }
  
  .remove-image-button {
    background-color: transparent;
    color: var(--text-color);
    border: 1px solid var(--content-border-color);
    border-radius: 4px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .remove-image-button:hover {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
  }
  
  .no-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  .no-image-placeholder {
    width: 250px;
    height: 180px;
    border: 2px dashed var(--content-border-color);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--text-secondary);
    background-color: var(--background-color);
    padding: 2rem;
  }
  
  .no-image-placeholder svg {
    opacity: 0.6;
    margin-bottom: 1rem;
  }
  
  .no-image-placeholder p {
    margin: 0;
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    .page-image-manager {
      padding: 1rem;
    }
    
    .image-actions {
      -direction: column;
      gap: 0.5rem;
      width: 100%;
    }
    
    .change-image-button,
    .remove-image-button,
    .select-image-button {
      width: 100%;
    }
  }