/* client/src/style/iconSwitch.css */

.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 21px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(10px);
  }
  
  .slider.round {
    border-radius: 10px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }