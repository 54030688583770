/* client/src/styles/pages/buildingBlocks.css */

.default-block-header {
    
}

.default-block-pageImg {
    float: right;
    margin-left: 1rem;
    width: 100px;
    height: 100px;
}

.detail-block-leading-subtext {
    font-style: italic;
}

.details-block-prereq {
}


