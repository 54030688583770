.lead-text {
    font-weight: bold;
}

.left {
    text-align: left;
    display: block;
}

.text-center {
    text-align: center;
}

.small-text {
    font-size: small;
}

.text-one {
    font-size: 1em;
}

.indent { 
    margin-left: 15px;
}