.image-circle-wrapper1a {
    width: 275px;              /* Fixed width */
    height: 275px;             /* Fixed height */
    border-radius: 50%;        /* Makes the container circular */
    overflow: hidden;          /* Hides the overflowing parts of the image */
  }

.image-circle-wrapper2a {
    width: 275px;              /* Fixed width */
    height: 500px;             /* Fixed height */
    border-radius: 50%;        /* Makes the container circular */
    overflow: hidden;          /* Hides the overflowing parts of the image */
  }

  .image-circle-wrapper2b {
    width: 275px;              /* Fixed width */
    height: 50vh;             /* Fixed height */
    min-height: 250px;
    border-radius: 50%;        /* Makes the container circular */
    overflow: hidden;          /* Hides the overflowing parts of the image */
  }

  .image-square-wrapper1a {
    width: 275px;              /* Fixed width */
    height: 275px;             /* Fixed height */
    border-radius: 5rem;        /* Makes the container circular */
    overflow: hidden;          /* Hides the overflowing parts of the image */
  }

  .image-square-wrapper1b {
    width: 500px;              /* Fixed width */
    height: 500px;             /* Fixed height */
    border-radius: 5rem;        /* Makes the container circular */
    overflow: hidden;          /* Hides the overflowing parts of the image */
  }

  .image-square-wrapper2a {
    width: 25vw;              
    min-width: 400px;
    height: 25vw;             
    min-height: 400px;
    border-radius: 1rem;        
    overflow: hidden;          /* Hides the overflowing parts of the image */
}

.image-square-wrapper2a img {
  width: 100%;                /* Ensures the image fills the wrapper horizontally */
  height: 100%;               /* Ensures the image fills the wrapper vertically */
  object-fit: cover;          /* Maintains aspect ratio and covers the container */
  object-position: center;    /* Centers the image within the container */
}

/* -------------------------------------------------------------------- */
/* Styles used for content pages to create a banner image               */
/* -------------------------------------------------------------------- */

.image-container {
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  height: 150px;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow: hidden;
  display: block; /* Changed to block display */
  margin-top: -18px;
}

.contained-img {
  position: absolute; /* Position absolutely within container */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the image */
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 30%;
  min-width: 100vw; /* Force full viewport width */
  min-height: 100%; /* Ensure full height coverage */
}

.image-container2 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 250px;
  display: block; /* Changed to block display */
  margin-top: -18px;
  margin-bottom: 20px;
}

.contained-img2 {
  position: absolute; /* Position absolutely within container */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the image */
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  min-width: 100vw; /* Force full viewport width */
  min-height: 100%; /* Ensure full height coverage */
}


/* -------------------------------------------------------------------- */
/* Styles used for content pages to align images to the right           */
/* -------------------------------------------------------------------- */

.rightside-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.rightside-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* -------------------------------------------------------------------- */




.contained-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 3rem;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0 20px;
  z-index: 100000000000;
}

/* -------------------------------------------------------------------- */
/* Generic Image Styles                                                 */
/* -------------------------------------------------------------------- */

.thumbnail-img {
  height: 120px;
  width: 120px;
  object-fit: cover;
}

.img-right {
  display: block !important;
  float: right;
  margin: 1rem;

}

.img-circle {
  border-radius: 50%; 
  object-fit: cover; 
  overflow: hidden;
}

.img-rounded {
  border-radius: 5%; 
  object-fit: cover; 
  overflow: hidden;
}

.img-100 {
  width: 100px;
  height: 100px;
}

.img-200 {
  width: 200px;
  height: 200px;
}

.img-300 {
  width: 300px;
  height: 300px;
}

.img-400 {
  width: 400px;
  height: 400px;
}


.img-300-nat { 
  max-width: 300px;  
  height: auto;      
}

.img-400-nat { 
  max-width: 400px;  
  height: auto;      
}

@media (max-width: 767px) {
  .img-right {
      float: none;
  }
}

