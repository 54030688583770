/* client/src/components/user/userSearch.css */
.user-search-container {
    position: relative;
    display: inline-block;
  }
  
  .user-search-popup {
    position: absolute;
    top: 100%;
    width: 300px;
    background: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 1rem;
    margin-top: 0.5rem;
  }
  
  .popup-align-left {
    left: 0;
  }
  
  .popup-align-right {
    right: 0;
  }
  
  .popup-align-center {
    left: 50%;
    transform: translateX(-50%);
  }
  
  .search-results {
    max-height: 200px;
    overflow-y: auto;
    margin-top: 0.5rem;
  }
  
  .search-result-item {
    padding: 0.5rem;
    cursor: pointer;
    border-bottom: 1px solid var(--border-color);
  }
  
  .search-result-item:hover {
    background-color: var(--hover-color);
  }