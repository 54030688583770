.image-carousel {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  
  .image-carousel img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .image-carousel img.active {
    opacity: 1;
  }
  
  .carousel-dots {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 20px;
  }
  
  .carousel-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid white;
    padding: 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .carousel-dot.active {
    background-color: white;
  }