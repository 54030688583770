.message-notification {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #ff4444;
    color: white;
    border-radius: 50%;
    padding: 2px 2px;
    font-size: 8px;
    min-width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-alias {
    position: relative;
    display: inline-block;
}

.message-count {
    background-color: #ff4444;
    color: white;
    border-radius: 10px;
    padding: 0 6px;
    font-size: 12px;
    margin-left: 8px;
}