/* client/src/styles/icon/iconCheck.css */
.check-button {
    background: #00c853;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
    padding: 0;
}

.check-button:hover {
    background: #00a844;
}

.check-button-small {
    width: 15px;
    height: 15px;
    font-size: 15px;
    line-height: 1;
}