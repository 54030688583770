  /* client/src/styles/icondEdit.css */
  
  .edit-icon {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 10px;
  }
  
  .edit-icon svg {
    width: 16px;
    height: 16px;
    color: #666;
    transition: color 0.3s ease;
  }
  
  .edit-icon:hover svg {
    color: #333;
  }

  .floating-edit-icon {
    position: fixed;
    top: 70px;
    right: 20px;
    cursor: pointer;
    z-index: 2000;
  }
  
  .floating-edit-icon svg {
    width: 24px;
    height: 24px;
  }