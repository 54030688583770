/* /client/src/pages/admin/pages/editPage.css */
.edit-page-container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 0 1rem;
    position: relative;
    min-height: calc(100vh - 4rem);
 }
 
 .tabs {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #ccc;
 }
 
 .tab {
    padding: 0.75rem 1.5rem;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1rem;
    position: relative;
    color: #666;
 }
 
 .tab.active {
    color: #007bff;
 }
 
 .tab.active::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #007bff;
 }
 
 .tab:hover {
    color: #0056b3;
 }
 
 .tab-content {
    margin-bottom: 5rem;  /* Space for floating buttons */
 }
 
