textarea, input {
  color: var(--text-color);
  background-color: var(--form-background);
  line-height: 1.5;
  font-family: 'League Spartan', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;  
}


.dropdown-menu,
.suggestion-list {
  position: static;
  top: auto;
  left: auto; 
  width: 200px;
  border: 1px solid #ddd;
  margin-left: 10px;
  padding: 5px; 
}

.dropdown-menu option,
.suggestion-list li {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.dropdown-menu option:nth-child(even),
.suggestion-list li:nth-child(even) {
  background-color: #f5f5f5;
}

.dropdown-menu option:nth-child(odd),
.suggestion-list li:nth-child(odd) {
  background-color: #ffffff;
}

.dropdown-menu option:hover,
.suggestion-list li:hover {
  background-color: #e8e8e8;
}

.search-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  margin-bottom: -1px; /* Makes it connect with suggestion list */
}

input[type="text"] {
  width: 100%; /* Full width of its parent container */
  max-width: 360px; /* Optionally restricts the maximum width */
  min-width: 250px; /* Ensures the input doesn't get too small on narrow screens */
  padding: 8px 12px; /* Good for touch targets and readability */
  box-sizing: border-box; /* Includes padding and border in the width */
}

textarea {
  min-width: 300px;  
  min-height: 150px; 
  width: 100%;     
  padding: 8px;     
  box-sizing: border-box;
  border: 1px solid #ccc; 
  border-radius: 4px;    
  resize: vertical;   

}

.select-menu {
  width: fit-content; 
  min-width: 100px; 
  padding: 8px 12px; 
  box-sizing: border-box; 
  border: 1px solid #ccc; 
  border-radius: 4px; 
  background-color: white; 
}

.select-option {
  background-color: blue; /* Light gray background */
  color: black; /* Text color */
}