[data-theme='dark'] {
  --background-color: #333333;
  --text-color: #ffffff;
  --primary-color: #D4AF37;
  --secondary-color: #F2E8C9;
  --icon-color: #aaaaaa;
  --icon-hover-color: #ffffff;
  --link-color: #F2E8C9;
  --section-block-background: #444444;  
  --form-background: #2c2c2c;
  --margin-fill-color: #2a2a2a; 
  --content-border-color: #3a3a3a;
}