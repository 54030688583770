/* client/src/components/images/imageSelector.css */

.image-selector-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
  }
  
  .image-selector-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
  
  .image-selector-content {
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    background-color: var(--background-color);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    overflow: hidden;
  }
  
  .image-selector-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid var(--content-border-color);
  }
  
  .image-selector-header h2 {
    margin: 0;
    font-size: 1.25rem;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    line-height: 1;
    padding: 0;
    color: var(--text-color);
  }
  
  .image-selector-tabs {
    display: flex;
    border-bottom: 1px solid var(--content-border-color);
  }
  
  .tab-button {
    flex: 1;
    padding: 0.75rem;
    background: none;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;
    color: var(--text-color);
  }
  
  .tab-button.active {
    border-bottom-color: var(--primary-color);
    color: var(--primary-color);
  }
  
  .image-selector-body {
    flex: 1;
    padding: 1rem;
    overflow-y: auto;
  }
  
  .image-selector-footer {
    padding: 1rem;
    border-top: 1px solid var(--content-border-color);
    display: flex;
    justify-content: flex-end;
  }
  
  .cancel-button {
    padding: 0.5rem 1rem;
    border: 1px solid var(--content-border-color);
    background-color: var(--background-alt);
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .cancel-button:hover {
    background-color: var(--hover-color);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .image-selector-content {
      width: 95%;
      max-height: 95vh;
    }
    
    .image-selector-header h2 {
      font-size: 1.1rem;
    }
    
    .tab-button {
      padding: 0.5rem;
      font-size: 0.9rem;
    }
    
    .image-selector-body {
      padding: 0.75rem;
    }
  }
  
  @media (max-width: 480px) {
    .image-selector-tabs {
      flex-direction: column;
    }
    
    .tab-button {
      border-bottom: 1px solid var(--content-border-color);
    }
    
    .tab-button.active {
      border-left: 2px solid var(--primary-color);
      border-bottom: 1px solid var(--content-border-color);
    }
  }