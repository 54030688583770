/* client/src/components/images/imageUploader.css */

.image-uploader-label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: block;
  }
  
  .image-uploader-dropzone {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
    text-align: center;
    border: 2px dashed var(--content-border-color);
    border-radius: 5px;
    padding: 20px;
    cursor: pointer;
    background-color: var(--form-background);
    transition: all 0.3s ease;
  }
  
  .image-uploader-dropzone:hover {
    border-color: var(--primary-color);
  }
  
  .image-uploader-dropzone.has-image {
    border-style: solid;
  }
  
  .message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .upload-icon {
    color: var(--text-color);
    opacity: 0.6;
    margin-bottom: 1rem;
  }
  
  .preview {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .preview img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .remove-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(220, 53, 69, 0.8);
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 20px;
    line-height: 1;
    cursor: pointer;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .remove-button:hover {
    background-color: rgb(220, 53, 69);
  }
  
  .file-input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  
  .error-message {
    color: #dc3545;
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
  
  .loading-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
  }
  
  .spinner {
    width: 20px;
    height: 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid var(--primary-color);
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
    margin-right: 0.5rem;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }