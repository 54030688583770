

/* ---------------------------------------------------------------------- */
/* Styles used for content pages to keep the intro img inside a good size */
/* ---------------------------------------------------------------------- */

.page-img-container {

  }
  
  .page-img {
  }
  
  /* -------------------------------------------------------------------- */

  .author {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .section-desc img {
    max-width: 500px;
    border: 3px solid;
    border-radius: 5%;
    border-color: rgb(208, 204, 204);
  }