/* client/src/styles/icon/iconDelete.css */

.delete-button {
  /*  background: #ff4444;
  /*  color: white; */
  /*  border: none;
  /*  border-radius: 50%; */
    width: 20px;
    height: 20px;
   /* font-size: 20px;
   /* line-height: 1;
   /* cursor: pointer;
   /* display: flex;
   /* align-items: center;
   /* justify-content: center; */
   /* transition: background-color 0.2s;  */
   /* padding: 0;  removing turns red circle into oval */
  }
  


  .delete-button:hover {
    background: #cc0000;
  } 

  .delete-button-small {
    width: 15px;
    height: 15px;
    font-size: 15px;
  }


  


  