/* client/src/styles/form-fields/textArea.css */

/* Container alignment */
.rc-md-navigation {
  display: flex;
  align-items: center;
  padding: 0;
  gap: 0;
}

/* Button wrap alignment */
.button-wrap {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

/* Base button styles */
.rc-md-navigation .button,
.rc-md-navigation span[class^="button-type-"] {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  min-width: 32px;
  margin: 0;
  padding: 8px;
  background: transparent;
  color: var(--text-color);
}

/* Specific button types - extending base styles */
.rc-md-navigation .button-type-header,
.rc-md-navigation .button-type-bold,
.rc-md-navigation .button-type-italic,
.rc-md-navigation .button-type-underline,
.rc-md-navigation .button-type-quote,
.rc-md-navigation .button-type-olist,
.rc-md-navigation .button-type-ulist,
.rc-md-navigation .button-type-link,
.rc-md-navigation .button-type-image,
.rc-md-navigation .button-type-code {
  background: transparent !important;
  color: var(--text-color) !important;
}

/* Handle hover states */
.rc-md-navigation .button:hover,
.rc-md-navigation span[class^="button-type-"]:hover {
  background-color: var(--hover-color) !important;
}

/* Remove any margins between buttons */
.rc-md-navigation .button + .button {
  margin: 0;
}

.custom-md-editor {
  min-height: 200px;
  height: 50vh;
}