/* client/src/styles/iconSave.css */

.save-icon {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 10px;
  }
  
  .save-icon svg {
    width: 24px;
    height: 24px;
    color: #4CAF50;
    transition: color 0.3s ease;
  }
  
  .save-icon:hover svg {
    color: #45a049;
  }
  
  .alias-edit {
    display: flex;
    align-items: center;
  }
  
  .alias-edit input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }