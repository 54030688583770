.banner100 {
    max-height: 100px;
    width: 100vw;
    object-fit: cover;
    object-position: center;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.banner-text {
    color: #f4e097;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}