/* client/src/style/components/collapsiblePanel.css */

.collapsible-panel {
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .collapsible-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem;
    background: none;
    border: none;
    color: var(--text-color);
    cursor: pointer;
    font-size: 1rem;
    text-align: left;
  }
  
  .collapsible-header:hover {
    color: var(--primary-color);
  }
  
  .collapsible-content {
    padding: 1rem 0;
  }

  /* in styles/components/collapsiblePanel.css */

.collapsible-header svg {
  width: 16px;  /* or whatever size works best */
  height: 16px;
  margin-right: 8px;  /* space between icon and text */
  transition: transform 0.2s ease;  /* smooth rotation if you want it */
}

/* Optional: add some nice hover effects */
.collapsible-header:hover svg {
  color: var(--primary-color);  /* or whatever color you want for hover */
}

/* Optional: if you want to rotate the chevron-down on open */
.collapsible-header[aria-expanded="true"] svg {
  transform: rotate(180deg);
}