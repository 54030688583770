.login-button {
    padding: 0.5rem 1rem;
    background-color: #3b82f6;  /* this was the blue-500 color */
    color: white;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #2563eb;  /* this was the blue-600 color */
  }