/* client/src/pages/admin/pages/create/createNPC.css */
.details-section {
    margin: 20px 0;
}

.detail-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    gap: 10px;
}

.detail-row label {
    min-width: 100px;
    font-weight: 500;
}

.detail-input-group {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 10px;
}

.detail-input-group input {
    flex-grow: 1;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.icon-button:hover {
    background-color: var(--error-color);
    color: white;
}

.icon-button svg {
    width: 16px;
    height: 16px;
}

.form-row {
    margin-bottom: 20px;
}

.full-width {
    width: 100%;
}