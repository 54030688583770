/* client/src/styles/quoteStyles.css */

.quote-container {
    position: relative;
    width: calc(100% + 40px); 
    margin-left: -20px; 
    margin-right: -50px;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-top: 1px solid var(--content-border-color);
    border-bottom: 1px solid var(--content-border-color);
}

/* ------------------------------------------------------- */
/* Overlays image w/transparent black fill                 */
/* ------------------------------------------------------- */

.quote-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 2; 
}

/* ------------------------------------------------------- */
/* Causes image to be zoomed and fill appropriately        */
/* ------------------------------------------------------- */

.quote-container img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.5);
    object-position: center 37%;
}

/* ------------------------------------------------------- */
/* Overlays image w/transparent black fill                 */
/* ------------------------------------------------------- */

.quote-overlay {
    position: relative;
    z-index: 3;
    color: white;
    padding: 2rem;
    text-align: center;
    max-width: 800px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 2em;
}

.quote-desc {
  color: red;
}

.quote-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem; /* Adjust space between elements */
}

.quote-author {
    margin-top: 1rem;
    font-style: italic;
    font-size: .5em;
}


