/* client/src/styles/icon/icons.css */

.icon {
  color: var(--text-color) !important;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer; 
}





.icon:hover {
  opacity: 0.8;
}

/* Establish size of icon */

.i12 {
  width: 12px;
  height: 12px;
}  

.i20 {
  width: 20px;
  height: 20px;
}

.i24 {
    width: 24px;
    height: 24px;
  }

/* Positioning of icons  */

  .icon-float-right {
    position: absolute;
    top: 20px;
    right: 20px;
  }


  