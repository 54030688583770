/* client/src/styles/grid/twoColumn.css */

.two-column,
.two-column-stretch,
.two-column-6040,
.two-column-7030,
 .three-column,
 .three-column-fixed,
 .three-column-flex, 
 .four-column {
  display: grid;
  gap: 20px;
  width: fit-content;
  margin: auto;
  padding: 10px;
}

.two-column-stretch {
  display: grid;
  gap: 20px;
  align-items: stretch;
}

/* ------------------------------------------------------------------ */
/*                      2 COLUMN STYLES                               */
/* ------------------------------------------------------------------ */

.two-column {
    grid-template-columns: auto auto; 
  }

  .two-column-fixed-right {
    grid-template-columns: auto 1fr; 
  }

  .two-column-fixed-left {
    grid-template-columns: 1fr auto; 
  }


  .two-column-5050 {
    grid-template-columns: 50% 50%; 
  }

  .two-column-6040 {
    grid-template-columns: 60% 40%; 
  }

  .two-column-7030 {   
    grid-template-columns: 70% 30%; 
  }

/* ------------------------------------------------------------------ */
/*                      3 COLUMN STYLES                               */
/* ------------------------------------------------------------------ */

  .three-column {
    grid-template-columns: auto auto auto; 
  }

  .three-column-fixed {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%; 
  }

  .three-column-flex {
    display: grid;
    grid-template-columns: 1fr auto auto;
    width: 100%; 
  }

/* ------------------------------------------------------------------ */
/*                      4 COLUMN STYLES                               */
/* ------------------------------------------------------------------ */  

  .four-column {
    grid-template-columns: auto auto auto auto; 
  }

  .four-column-fixed {
    grid-template-columns: 25% 25% 25% 25%; 
  }

/* ------------------------------------------------------------------ */
/*                      MISC COLUMN STYLES                            */
/* ------------------------------------------------------------------ */

 .full-width {
    grid-column: 1 / -1; /* Spans from the first to the last column */
  }
  
.stretch {
  align-items: stretch;
}

.fill {
  width: 100%;
}

.fill75 {
  width: 75%;
  margin: 0 auto;
}

.grid-left {
    justify-self: start;  
}

.relative {
  position: relative;
}

.column-4 {
  column-count: 4;
  column-gap: 40px;

}





/* Tablet and Mobile breakpoint */

@media screen and (max-width: 1024px) {
  .three-column,
  .three-column-fixed,
  .three-column-flex {
    grid-template-columns: 1fr 1fr; /* Changes to two columns */
  }
}

@media screen and (max-width: 768px) {
  .two-column,
  .two-column-stretch,
  .two-column-6040,
  .two-column-7030, 
  .three-column,
  .three-column-fixed,
  .three-column-flex {
    grid-template-columns: 1fr; /* Changes to single column */
  }
}

/* Optional: Additional breakpoint for very small screens */
@media screen and (max-width: 480px) {
  .two-column,
  .two-column-stretch,
  .two-column-6040,
  .two-column-7030,
  .three-column,
  .three-column-fixed,
  .three-column-flex {
    gap: 5px; /* You might want to reduce the gap on very small screens */
    padding: 5px;
  }
}