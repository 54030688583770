/* styles/components/tagInput.css */
.tag-input-container {
    width: 100%;
}

.tag-input-row {
    display: flex;
    align-items: center;
    gap: 12px;
}

.tag-label {
    white-space: nowrap;
}

.tag-input-form {
    display: flex;
    align-items: center;
    gap: 8px;
    min-width: 150px; /* Adjust as needed */
}

.tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
}

.tag {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    background-color: var(--form-background);
    border-radius: 4px;
    border: 1px solid var(--content-border-color);
}

.tag-input {
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid var(--content-border-color);
    background-color: var(--form-background);
}

