/* /client/src/styles/margins.css */

.margin-L-Half {
    margin-left: 0.5rem;
}

.margin-LR-Half {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.margin-LR1 {
    margin-left: 1rem;
    margin-right: 1 rem;
}