button,
.button,
a.button,
input[type="submit"],
input[type="reset"],
input[type="button"] {

}

button:hover,
.button:hover,
a.button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover {
}

.mag-search-button {
    display: flex;
    gap: 8px;
  }
  
  .mag-search-button::before {
    content: "🔍";  /* You could alternatively use a proper icon font or SVG */
    font-size: 14px;
  }
  
  .mag-search-button:hover {
  }







.admin-buttons {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.admin-button {
}

.admin-button:hover {
  background-color: var(--secondary-color);
}

.floating-buttons {
  display: flex;
  gap: 10px;
  z-index: 1000;
}

.floating-update-button,
.floating-cancel-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.floating-update-button {
  right: 120px; 
}

.floating-cancel-button {
  right: 20px;
}

.disabled-button {
  background-color: var(--secondary-color);
  color: var(--text-color);
  cursor: not-allowed;
}

.button-container {
  position: absolute;
  top: 0;
  right: 0;
}

.edit-button {
  display: inline-block;
  padding: 8px 12px;
  background-color: var(--primary-color);
  color: var(--background-color);
  text-decoration: none;
  border-radius: 4px;
  font-size: 0.9rem;
}

.edit-button:hover {
  background-color: var(--secondary-color);
}

.edit-button.floating {
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 1000;
}

/* -------------------------- End Button Styling ------------------------------ */

/* /client/src/styles/buttonStyles.css */

.floating-action-button-container {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  gap: 1rem;
  z-index: 1000;
}

.floating-action-button {
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
}

.floating-action-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.floating-action-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.floating-action-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.floating-action-button.secondary {
  background-color: #6c757d;
}

.floating-action-button.secondary:hover {
  background-color: #5a6268;
}

.floating-action-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
  transform: none;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  opacity: 0.8;
}

.floating-action-button:disabled:hover {
  transform: none;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  background-color: #6c757d;
}

.floating-icon {
  position: absolute;
  top: 20px;
  right: 20px;
}

.floating-icon svg {
}

.floating-icon:hover {
}




