/* client/src/styles/pages/view/shortStoryView.css */
.ss-page-img-container {
    max-width: 100%;
    max-height: 100%;
    max-width: 750px;
    max-height: 500px;
    overflow: hidden;
  }

  .ss-page-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  