/* Main navbar styles */
.navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    transition: background-color 0.3s ease;
    z-index: 1000;
  }
  
  .navbar.scrolled {
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  /* Navbar content layout */
  .navbar-content {
    display: flex;
    justify-content: space-between;
    width: 90%;
  }
  
  /* Navbar brand (left side) styles */
  .navbar-left .navbar-brand {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
  }
  
  /* Navbar links and right side content */
  .navbar-right {
    display: flex;
    align-items: center;
  }
  
  .navbar-link {
    color: white;
    text-decoration: none;
    margin-left: 15px;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
  }
  
  .navbar-link:hover {
    text-decoration: underline;
  }
  
  /* User info and avatar styles */
  .user-info {
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
  
  /* Navbar line separator */
  .navbar-line {
    width: 90%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.3);
    margin-top: 10px;
  }
  
  /* Remove borders and outlines */
  .navbar, .navbar * {
    border: none !important;
    outline: none !important;
  }
  
  /* User Menu Styles */
  .user-menu {
    position: relative;
  }
  
  .user-avatar {
    cursor: pointer;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    z-index: 1001;
  }
  
  .dropdown-item {
    display: block;
    padding: 10px 15px;
    color: #333;
    text-decoration: none;
    white-space: nowrap;
  }
  
  .dropdown-item:hover {
    background-color: #f5f5f5;
  }
  
  button.dropdown-item {
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;
  }

  /* Mobile menu button - hidden by default */
.mobile-menu-button {
  display: none;
  background: none;
  cursor: pointer;
  padding: 10px;
}

.mobile-menu-button span {
  display: block;
  width: 15px;
  height: 2px;
  background-color: white;
  margin: 2px 0;
  transition: 0.3s;
}

/* Mobile menu overlay */
.mobile-menu-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 1002;
}

.mobile-menu-content {
  display: flex;
  flex-direction: column;
  padding-top: 60px;
}

.mobile-menu-item {
  color: white;
  text-decoration: none;
  padding: 15px;
  text-align: center;
  font-size: 1.2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.close-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 30px;
  background: none;
  border: none;
  cursor: pointer;
}

/* Media query for mobile devices */
@media (max-width: 650px) {
  .mobile-menu-button {
    display: flex;
    flex-direction: column;
  }

  .navbar-right.desktop-menu {
    display: none;
  }

  .mobile-menu-overlay {
    display: block;
  }
}