/* styles/viewMessages.css */
.message-list {
    max-width: 800px;
    margin: 20px auto;
    padding: 0 15px;
}

.message-filters {
    margin-bottom: 20px;
    border-bottom: 1px solid #e1e1e1;
    padding: 10px 0;
}

.message-filters button {
    background: none;
    border: none;
    padding: 8px 16px;
    margin-right: 10px;
    cursor: pointer;
    color: #666;
    font-size: 14px;
    border-radius: 15px;
    transition: all 0.2s ease;
}

.message-filters button:hover {
    background: #f0f0f0;
}

.message-filters button.active {
    background: #007bff;
    color: white;
}

.messages {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.message {
    background: white;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 15px;
    transition: all 0.2s ease;
}

.message.unread {
    border-left: 4px solid #007bff;
    background: #f8f9fa;
}

.message:hover {
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.message-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
    font-size: 13px;
}

.message-type {
    background: #e9ecef;
    padding: 3px 8px;
    border-radius: 12px;
    color: #495057;
}

.message.unread .message-type {
    background: #007bff;
    color: white;
}

.message-date {
    color: #6c757d;
    margin-right: auto;
}

.message button {
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    transition: all 0.2s ease;
}

.message button.mark-read {
    background: #28a745;
    color: white;
}

.message button.mark-read:hover {
    background: #218838;
}

.message button.delete {
    background: #dc3545;
    color: white;
}

.message button.delete:hover {
    background: #c82333;
}

.message-content {
    color: #212529;
    line-height: 1.5;
    white-space: pre-wrap;
}

.no-messages {
    text-align: center;
    padding: 40px;
    color: #6c757d;
    font-style: italic;
    background: #f8f9fa;
    border-radius: 8px;
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
    .message-header {
        flex-wrap: wrap;
    }
    
    .message-date {
        width: 100%;
        order: -1;
        margin-bottom: 5px;
    }
    
    .message-filters {
        display: flex;
        overflow-x: auto;
        padding-bottom: 10px;
        -webkit-overflow-scrolling: touch;
    }

    .message-filters button {
        flex: 1;
        white-space: nowrap;
    }
}