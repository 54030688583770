.page-access-block {
    padding: 1rem;
    background: var(--background-alt);
    border-radius: 4px;
}

.page-access-block .form-group {
    margin-bottom: 1.5rem;
}

.page-access-block label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.contributors-list {
    margin-top: 1rem;
}

.contributor-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    background: var(--background-light);
    border-radius: 4px;
    margin-bottom: 0.5rem;
}

.remove-button {
    padding: 0.25rem 0.5rem;
    background: var(--danger);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.remove-button:hover {
    background: var(--danger-hover);
}