/* client/src/styles/components/pageTabs.css */

.tabs-container {
    width: 100%;
}

.tabs {
    display: flex;
    gap: 8px;
}

.tab {
    border: none;
    background: none;
    cursor: pointer;
    color: var(--text-color);
    border-bottom: 2px solid transparent;
}

.tab.active {
    color: var(--primary-color);
}

.tab-content {
}