/* client/src/components/userSettings.css */

.user-settings {
    text-align: left;
  }
  
  .user-settings hr {
    max-width: 75%;
    margin-left: 0;
    margin-right: auto;
  }

  .settings-grid p {
    margin: 5px;
  }
  

  /* ------------------------------------------------------- */
  /* Page Layout                                             */
  /* ------------------------------------------------------- */

  .settings-grid {
    display: grid;
    grid-template-columns: auto 1fr;  /* First column auto-sized to content, second takes remaining space */
    gap: 1rem 2rem;  /* Row gap 1rem, column gap 2rem */
    align-items: center;
    margin: 0;

  }
  
  .settings-labels p,
  .settings-values > * {
  }
  

  .settings-labels {
    display: contents;
  }
  
  .settings-values {
    display: contents;
  }
  
  .alias-display {
    display: flex;
    align-items: center;
  }
  
  .alias-display p {
    margin-right: 10px;
  }