/* client/src/styles/components/markdownEditor.css */

/* Target the main editing area */
.rc-md-editor .editor-container .sec-md .input {
  font-size: 1.3rem !important;
  line-height: 1.1 !important;
  background-color: var(--form-background) !important;
  color: var(--text-color) !important;
}

.rc-md-editor .rc-md-navigation .button-wrap .button {
  color: #575757 !important;
}

/* Toolbar styling */
.rc-md-editor .tool-bar {
  background-color: var(--form-background) !important;
  border-bottom: 1px solid var(--content-border-color) !important;
}

/* Editor section container */
.rc-md-editor .editor-container>.section>.section-container {
  padding: 10px !important;
}

/* Toolbar buttons */
.rc-md-editor .tool-bar .button-wrap .button {
  font-size: 14px !important;
  color: var(--text-color) !important;
}

.rc-md-editor .tool-bar .button-wrap .button:hover {
  color: var(--link-color) !important;
}

.rc-md-editor .rc-md-navigation {
  padding: 0 4px;  /* Reduce from 0 8px */
}

.rc-md-editor .rc-md-navigation .button-wrap .button {
  padding: 4px;  /* Reduce from current padding */
  min-width: 20px;  /* Reduce from 24px if needed */
  height: 24px;  /* Reduce from 28px if needed */
  margin: 0 2px;  /* Adjust margins */
}
