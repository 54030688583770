.autocomplete-container {
    position: relative;
    width: 100%;
}

.searching-indicator {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
    font-size: 0.8em;
}

.autocomplete-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    background: white;
    border: 1px solid #ddd;
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1000;
}

.autocomplete-item {
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.autocomplete-item:hover {
    background-color: #f5f5f5;
}

.autocomplete-item:not(:last-child) {
    border-bottom: 1px solid #eee;
}

.tags-section {
    margin: 10px 0;
}

.tag-input-row {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.tag-input {
    flex: 1;
}

.tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.tag {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 4px;
}

.tag button {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
}