/* client/src/styles/app.css */

/* ---------------------------------------------------------------------------- */
/* -----------                 Global HTML Element Styling          ----------- */
/* ---------------------------------------------------------------------------- */


html, body {
  width: 100%;
  overflow-x: hidden;
}



body {
  margin: 0;
  color: var(--text-color);
  line-height: 1.4;
  font-family: 'League Spartan', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.3rem;
  background-color: var(--margin-fill-color);
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  overflow-y: hidden;

}




h1 {
  font-size: 3rem;
  font-weight: bolder;
  letter-spacing: 0.05em;
  color: var(--primary-color);
  font-family: Montserrat;
  margin-top: 0px;
  margin-bottom: 0px;
} /* */

h2 {
  font-size: 1.8rem;
  font-family: Roboto;
  letter-spacing: 0.02em;
  font-weight: lighter;
  color: var(--text-color);
}

h3 {
  font-size: 1.5rem;
  color: var(--text-color);
}

a {
  color: var(--link-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

hr {
  border: none;
  height: 1px;
  background-color: #D3D3D3;   
}

blockquote {
  font-style: italic;
  font-size: .8em;
}

/* ------------------- End Global HTML Element Styling ------------------------ */


/* ---------------------------------------------------------------------------- */
/* -----------                 Homepage Banner Styling              ----------- */
/* ---------------------------------------------------------------------------- */

#banner {
  height: 80vh;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}

/* ------------------- End Homepage Banner Styling ---------------------------- */


nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: var(--background-color);
  color: var(--text-color);
  border-bottom: 1px solid var(--secondary-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.has-banner nav {
  background-color: transparent;
  transition: background-color 0.3s ease;
}

nav.scrolled {
  background-color: var(--background-color);
  opacity: 0.9;
}

nav a {
  margin: 5px;
  white-space: nowrap;
  color: var(--text-color);
}

.App {
  text-align: center;
}


#content {
  padding: 20px;
  background-color: var(--background-color);
  margin-top: 60px; /* Adjust this to match your navbar height */
}

.page-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;  
  padding: 0 50px; 
  box-sizing: border-box; 
  color: var(--text-color);
  border-left: 1px solid var(--content-border-color);
  border-right: 1px solid var(--content-border-color);
  background-color: var(--background-color); 
}

@media (max-width: 1240px) { /* 1200px + 20px padding on each side */
  .page-content {
    max-width: 1240px;
  }
}

.has-banner .page-content,
.has-banner #content {
  padding-top: 0;
  margin-top: 0;
}

/* You can keep these if you're using them, otherwise feel free to remove */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--text-color);
}

.App-link {
  color: var(--primary-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ---------------------------------------------------------------------------- */
/* -----------                 Text Input Styling                   ----------- */
/* ---------------------------------------------------------------------------- */

.text-input {
  width: 100%;
  padding: 8px 12px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1.1rem;
  font-family: 'League Spartan';
  color: var(--text-color);
  background-color: var(--background-color);
}

.text-input.number-input {
  -webkit-appearance: none; 
  margin: 0; 
  text-align: left;
  padding-left: 5px; /* Add left padding for the pseudo-element */
  position: relative; /* For positioning the pseudo-element */
}

.text-input.year-input {
  /* Add specific styles if needed */
}

.text-input.area-input {
  /* Add specific styles if needed */
}


/* ---------------------------------------------------------------------------- */
/* -----------                     Recent Updates Styling           ----------- */
/* ---------------------------------------------------------------------------- */

.recent-updates {
  margin: 0 -400px 0 -400px;
  background-color: var(--background-color);
  padding: 20px;
}

.recent-updates h1 {
  text-align: left;
  max-width: 75vw;
  margin: 0 auto;
  margin-top: 20px;
}

.updates-list {
  max-width: 75vw;
  margin: 0 auto;
  margin-top: 0px;
}

.update-group {
  /* Add styles if needed */
}

.update-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.update-header h3 {
  margin: 0;
}

.update-group h3 {
  text-align: left;
  margin-bottom: 0px;
  margin-top: 0px;
}

.update-group ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}

.update-group li {
  /* Add styles if needed */
}

.load-more-label {
  display: block;
  text-align: left;
  max-width: 75vw;
  margin: 0 auto;
  color: var(--text-color);
}

.load-more-label:hover {
  font-weight: bolder;
}

/* -------------------------- End Recent Updates Styling -------------------- */

.trigger-warning {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 5px;
  margin: 5px 0;
  border-radius: 4px;
}

.basepage-markdown p {
  margin: 0;
}

.inline {
display: inline-block;
}

.inline-markdown {
  display: inline;
}

.inline-markdown > div {
  display: inline;
}

.inline-markdown p {
  display: inline;
  margin: 0;
}

.slim-and-trim, .slim-and-trim li  {
  padding: 0;
  margin: 0;
  font-size: 1.1rem;
  line-height: 1.3rem;
}

.center {
align-items: center;
align-items: start; /* vertical top alignment */
  }

.tall { 
  min-height: 100vh;
}

.wide-percent {
  width: 100%;
}


.with-parentheses::before {
  content: "(";
}
.with-parentheses::after {
  content: ")";
}


.z0 {
  z-index: 0;
}

.z1 {
  z-index: 1;
}

.z100 {
  z-index: 100;
}