/* client/src/components/images/imageGallery.css */

.image-gallery {
    padding: 1rem;
    background-color: var(--background-color);
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  
  .image-gallery h3 {
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--content-border-color);
    padding-bottom: 0.5rem;
  }
  
  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;
    margin-top: 1rem;
  }
  
  .image-item {
    position: relative;
    height: 120px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid var(--content-border-color);
    transition: transform 0.2s ease;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .image-item:hover {
    transform: scale(1.03);
  }
  
  .image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(220, 53, 69, 0.8);
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 14px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  
  .image-item:hover .delete-button {
    opacity: 1;
  }
  
  .delete-button:hover {
    background-color: rgb(220, 53, 69);
  }
  
  .image-gallery-loading,
  .image-gallery-error,
  .image-gallery-empty {
    text-align: center;
    padding: 2rem;
  }
  
  .image-gallery-loading .spinner {
    width: 30px;
    height: 30px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top: 3px solid var(--primary-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto 1rem;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Responsive layout */
  @media (max-width: 768px) {
    .image-grid {
      grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    }
    
    .image-item {
      height: 90px;
    }
  }