:root {
  --background-color: #ffffff;
  --text-color: #575757;
  --primary-color: #815530;
  --secondary-color: #7f5938;
  --icon-color: #666666;
  --icon-hover-color: #ffffff;
  --link-color: #bf8a63; 
  --section-block-background: #f5f5f5;  
  --form-background: #f0f0f0;
  --margin-fill-color: #f5f5f5;
  --content-border-color: #e0e0e0; 
}