.story-list {
    display: flex;
    flex-direction: column;
    gap: 10px;  
  }
  
  .story-row {
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: 20px;
    text-decoration: none;  
  }