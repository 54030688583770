.small-label {
    width: 150px !important;
    min-width: 150px !important;
}

.big-desc {
    margin-left: 10px;
}


.delete-button {
    margin-left: 10px;
}

.delete-button {
    display: inline-block; /* Makes elements sit next to each other in the same line */
    vertical-align: middle; /* Aligns them neatly on the same line */
}
