.tag-add-button {
    padding: 4px;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.tag-add-button svg {
    width: 16px;
    height: 16px;
    color: var(--text-color);
}

.create-button {
    top: 20px;
    right: 20px;
    width: 20px;          /* Set specific size */
    height: 20px;         /* Set specific size */
    padding: 2px;         /* Space for the icon inside button */
    background: var(--primary-color);
    border: none;
    border-radius: 50%;   /* Make it circular */
    cursor: pointer;
    display: flex;        /* Center the icon */
    align-items: center;  /* Center the icon */
    justify-content: center; /* Center the icon */
}

.create-button svg {
    width: 24px;          /* Set icon size */
    height: 24px;         /* Set icon size */
    stroke: var(--background-color);  /* Color of the icon */
    stroke-width: 2;      /* Make lines visible */
}

.create-button:hover {
    background: var(--secondary-color);
}

.create-button-small {
    width: 15px;
    height: 15px;
    font-size: 15px;
    line-height: 1;
  }