.div-max-width-50 {
    max-width: 50%;
}

.div-100 {
    width: 100%;
}

.div-inline {
    display: inline;
}

.div-indent {
    padding-left: 40px;
}

