/* client/src/styles/components/footer.css */

.footer {
    padding-top: 20px;
    margin-top: 20px;
    max-width: 1200px;
    max-width: 70%;
    margin: 0 auto;  

}

.footer .navbar-link {
    color: var(--text-color);
    text-decoration: none;
    padding: 5px 0;
    margin: 0 10px;
    text-shadow: none;
}

.footer h6 {
    font-weight: normal;
    font-size: .75em;
  }