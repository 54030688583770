/* client/src/components/user/building-blocks/achievementBlock.css */
.achievement-block {
    position: relative;
    display: inline-block;
}

.achievement-item {
    position: relative;
    cursor: pointer;
}

.achievement-item.locked {
    opacity: 0.5;
}

.achievement-tooltip {
    position: absolute;
    bottom: 130%; /* Increased to give more space above the icon */
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    color: var(--text-primary);
    padding: 0.75rem 1rem;
    border-radius: 6px;
    font-size: 0.875rem;
    white-space: nowrap;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    border: 1px solid #e0e0e0;
}

.achievement-tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: white transparent transparent transparent;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
}

.tooltip-title {
    font-weight: 600;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    text-align: center;
}

.tooltip-description {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    color: var(--text-secondary);
    text-align: center;
}

.tooltip-progress {
    font-size: 0.75rem;
    color: var(--text-secondary);
    text-align: center;
    border-top: 1px solid #e0e0e0;
    padding-top: 0.5rem;
    margin-top: 0.5rem;
}

.achievement-item {
    position: relative;
    cursor: pointer;
}

.achievement-item.locked {
    opacity: 1; /* Changed from 0.5 */
    filter: grayscale(100%); /* Converts to grayscale */
}

.achievement-item.locked .achievement-tooltip {
    background-color: #f5f5f5; /* Slightly grayed background for locked tooltips */
}

.achievement-item.locked .achievement-tooltip::after {
    border-top-color: #f5f5f5; /* Match the grayed background */
}

.achievement-item.locked .tooltip-title {
    color: #666; /* Darker gray for locked achievement titles */
}

.achievement-item.locked .tooltip-progress {
    color: #888; /* Slightly lighter gray for progress text */
}